/*css Table Company*/

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

td,
th {
    border: hidden;
    text-align: left;
    padding: 0px;
    font-size: 10pt;
}

.style-bettween-div {
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

tr:nth-child(even) {
    background-color: white;
}

.divRounded {
    border-bottom: 1px solid #80808087;
    border-right: 0.5px solid #80808087;
}

.headerTitle {
    background: #009DE4;
    color: white;
    height: 34px;
}

.bodyRows {
    height: 35px;
}

.roundTable {
    border-collapse: separate;
    border-spacing: 10;
    border: solid black 1px;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 5px;
}

.MuiTableCell-root {
    padding: 8px !important;
}

.boldText {
    font-weight: bold !important;
}

.heightCustom {
    height: 380px;
}

.paddingPresent {
    padding: 0px !important;
}

@media (min-height: 295px) {
    .heightCustom {
        height: 10px;
    }
}

@media (min-height: 305px) {
    .heightCustom {
        height: 20px;
    }
}

@media (min-height: 315px) {
    .heightCustom {
        height: 30px;
    }
}

@media (min-height: 325px) {
    .heightCustom {
        height: 40px;
    }
}

@media (min-height: 335px) {
    .heightCustom {
        height: 50px;
    }
}

@media (min-height: 345px) {
    .heightCustom {
        height: 60px;
    }
}

@media (min-height: 355px) {
    .heightCustom {
        height: 70px;
    }
}

@media (min-height: 365px) {
    .heightCustom {
        height: 80px;
    }
}

@media (min-height: 375px) {
    .heightCustom {
        height: 90px;
    }
}

@media (min-height: 385px) {
    .heightCustom {
        height: 100px;
    }
}

@media (min-height: 395px) {
    .heightCustom {
        height: 110px;
    }
}

@media (min-height: 405px) {
    .heightCustom {
        height: 120px;
    }
}

@media (min-height: 415px) {
    .heightCustom {
        height: 130px;
    }
}

@media (min-height: 425px) {
    .heightCustom {
        height: 140px;
    }
}

@media (min-height: 435px) {
    .heightCustom {
        height: 150px;
    }
}

@media (min-height: 445px) {
    .heightCustom {
        height: 160px;
    }
}

@media (min-height: 455px) {
    .heightCustom {
        height: 170px;
    }
}

@media (min-height: 465px) {
    .heightCustom {
        height: 180px;
    }
}

@media (min-height: 475px) {
    .heightCustom {
        height: 190px;
    }
}

@media (min-height: 485px) {
    .heightCustom {
        height: 200px;
    }
}

@media (min-height: 495px) {
    .heightCustom {
        height: 210px;
    }
}

@media (min-height: 507px) {
    .heightCustom {
        height: 220px;
    }
}

@media (min-height: 517px) {
    .heightCustom {
        height: 230px;
    }
}

@media (min-height: 528px) {
    .heightCustom {
        height: 240px;
    }
}

@media (min-height: 536px) {
    .heightCustom {
        height: 250px;
    }
}

@media (min-height: 546px) {
    .heightCustom {
        height: 260px;
    }
}

@media (min-height: 556px) {
    .heightCustom {
        height: 270px;
    }
}

@media (min-height: 568px) {
    .heightCustom {
        height: 280px;
    }
}

@media (min-height: 578px) {
    .heightCustom {
        height: 290px;
    }
}

@media (min-height: 588px) {
    .heightCustom {
        height: 300px;
    }
}

@media (min-height: 599px) {
    .heightCustom {
        height: 315px;
    }
}

@media (min-height: 609px) {
    .heightCustom {
        height: 325px;
    }
}

@media (min-height: 619px) {
    .heightCustom {
        height: 335px;
    }
}

@media (min-height: 630px) {
    .heightCustom {
        height: 350px;
    }
}

@media (min-height: 640px) {
    .heightCustom {
        height: 360px;
    }
}

@media (min-height: 652px) {
    .heightCustom {
        height: 370px;
    }
}

@media (min-height: 660px) {
    .heightCustom {
        height: 380px;
    }
}

@media (min-height: 670px) {
    .heightCustom {
        height: 390px;
    }
}

@media (min-height: 680px) {
    .heightCustom {
        height: 400px;
    }
}

@media (min-height: 690px) {
    .heightCustom {
        height: 410px;
    }
}

@media (min-height: 700px) {
    .heightCustom {
        height: 420px;
    }
}

@media (min-height: 710px) {
    .heightCustom {
        height: 430px;
    }
}

@media (min-height: 720px) {
    .heightCustom {
        height: 440px;
    }
}

@media (min-height: 730px) {
    .heightCustom {
        height: 450px;
    }
}

@media (min-height: 740px) {
    .heightCustom {
        height: 460px;
    }
}

@media (min-height: 750px) {
    .heightCustom {
        height: 470px;
    }
}

@media (min-height: 760px) {
    .heightCustom {
        height: 480px;
    }
}

@media (min-height: 770px) {
    .heightCustom {
        height: 490px;
    }
}

@media (min-height: 780px) {
    .heightCustom {
        height: 500px;
    }
}

@media (min-height: 790px) {
    .heightCustom {
        height: 510px;
    }
}

@media (min-height: 800px) {
    .heightCustom {
        height: 520px;
    }
}

@media (min-height: 810px) {
    .heightCustom {
        height: 525px;
    }
}

@media (min-height: 820px) {
    .heightCustom {
        height: 535px;
    }
}

@media (min-height: 830px) {
    .heightCustom {
        height: 545px;
    }
}

@media (min-height: 840px) {
    .heightCustom {
        height: 555px;
    }
}

@media (min-height: 850px) {
    .heightCustom {
        height: 565px;
    }
}

@media (min-height: 860px) {
    .heightCustom {
        height: 575px;
    }
}

@media (min-height: 870px) {
    .heightCustom {
        height: 585px;
    }
}

@media (min-height: 880px) {
    .heightCustom {
        height: 595px;
    }
}

@media (min-height: 890px) {
    .heightCustom {
        height: 605px;
    }
}

@media (min-height: 900px) {
    .heightCustom {
        height: 615px;
    }
}

@media (min-height: 910px) {
    .heightCustom {
        height: 625px;
    }
}

@media (min-height: 920px) {
    .heightCustom {
        height: 635px;
    }
}

@media (min-height: 930px) {
    .heightCustom {
        height: 645px;
    }
}

@media (min-height: 940px) {
    .heightCustom {
        height: 655px;
    }
}

@media (min-height: 950px) {
    .heightCustom {
        height: 665px;
    }
}

@media (min-height: 960px) {
    .heightCustom {
        height: 675px;
    }
}

@media (min-height: 970px) {
    .heightCustom {
        height: 685px;
    }
}

@media (min-height: 980px) {
    .heightCustom {
        height: 695px;
    }
}

@media (min-height: 990px) {
    .heightCustom {
        height: 705px;
    }
}

@media (min-height: 1000px) {
    .heightCustom {
        height: 715px;
    }
}

@media (min-height: 1010px) {
    .heightCustom {
        height: 725px;
    }
}

@media (min-height: 1020px) {
    .heightCustom {
        height: 740px;
    }
}

@media (min-height: 1360px) {
    .heightCustom {
        height: 1070px;
    }
}


/*-----------table filter------------*/

.heightCustom2 {
    height: 380px;
}

@media (min-height: 465px) {
    .heightCustom2 {
        height: 10px;
    }
}

@media (min-height: 475px) {
    .heightCustom2 {
        height: 20px;
    }
}

@media (min-height: 485px) {
    .heightCustom2 {
        height: 30px;
    }
}

@media (min-height: 495px) {
    .heightCustom2 {
        height: 40px;
    }
}

@media (min-height: 507px) {
    .heightCustom2 {
        height: 50px;
    }
}

@media (min-height: 517px) {
    .heightCustom2 {
        height: 60px;
    }
}

@media (min-height: 528px) {
    .heightCustom2 {
        height: 70px;
    }
}

@media (min-height: 536px) {
    .heightCustom2 {
        height: 80px;
    }
}

@media (min-height: 546px) {
    .heightCustom2 {
        height: 90px;
    }
}

@media (min-height: 556px) {
    .heightCustom2 {
        height: 100px;
    }
}

@media (min-height: 568px) {
    .heightCustom2 {
        height: 130px;
    }
}

@media (min-height: 578px) {
    .heightCustom2 {
        height: 140px;
    }
}

@media (min-height: 588px) {
    .heightCustom2 {
        height: 150px;
    }
}

@media (min-height: 599px) {
    .heightCustom2 {
        height: 150px;
    }
}

@media (min-height: 609px) {
    .heightCustom2 {
        height: 160px;
    }
}

@media (min-height: 619px) {
    .heightCustom2 {
        height: 170px;
    }
}

@media (min-height: 630px) {
    .heightCustom2 {
        height: 181px;
    }
}

@media (min-height: 640px) {
    .heightCustom2 {
        height: 191px;
    }
}

@media (min-height: 652px) {
    .heightCustom2 {
        height: 201px;
    }
}

@media (min-height: 660px) {
    .heightCustom2 {
        height: 211px;
    }
}

@media (min-height: 670px) {
    .heightCustom2 {
        height: 221px;
    }
}

@media (min-height: 680px) {
    .heightCustom2 {
        height: 231px;
    }
}

@media (min-height: 690px) {
    .heightCustom2 {
        height: 241px;
    }
}

@media (min-height: 700px) {
    .heightCustom2 {
        height: 251px;
    }
}

@media (min-height: 710px) {
    .heightCustom2 {
        height: 261px;
    }
}

@media (min-height: 710px) {
    .heightCustom2 {
        height: 271px;
    }
}

@media (min-height: 720px) {
    .heightCustom2 {
        height: 281px;
    }
}

@media (min-height: 730px) {
    .heightCustom2 {
        height: 291px;
    }
}

@media (min-height: 740px) {
    .heightCustom2 {
        height: 301px;
    }
}

@media (min-height: 750px) {
    .heightCustom2 {
        height: 311px;
    }
}

@media (min-height: 760px) {
    .heightCustom2 {
        height: 321px;
    }
}

@media (min-height: 770px) {
    .heightCustom2 {
        height: 331px;
    }
}

@media (min-height: 780px) {
    .heightCustom2 {
        height: 341px;
    }
}

@media (min-height: 790px) {
    .heightCustom2 {
        height: 352px;
    }
}

@media (min-height: 800px) {
    .heightCustom2 {
        height: 362px;
    }
}

@media (min-height: 810px) {
    .heightCustom2 {
        height: 372px;
    }
}

@media (min-height: 820px) {
    .heightCustom2 {
        height: 382px;
    }
}

@media (min-height: 830px) {
    .heightCustom2 {
        height: 392px;
    }
}

@media (min-height: 840px) {
    .heightCustom2 {
        height: 402px;
    }
}

@media (min-height: 850px) {
    .heightCustom2 {
        height: 412px;
    }
}

@media (min-height: 860px) {
    .heightCustom2 {
        height: 422px;
    }
}

@media (min-height: 870px) {
    .heightCustom2 {
        height: 432px;
    }
}

@media (min-height: 880px) {
    .heightCustom2 {
        height: 442px;
    }
}

@media (min-height: 890px) {
    .heightCustom2 {
        height: 452px;
    }
}

@media (min-height: 900px) {
    .heightCustom2 {
        height: 462px;
    }
}

@media (min-height: 910px) {
    .heightCustom2 {
        height: 472px;
    }
}

@media (min-height: 920px) {
    .heightCustom2 {
        height: 492px;
    }
}

@media (min-height: 930px) {
    .heightCustom2 {
        height: 502px;
    }
}

@media (min-height: 940px) {
    .heightCustom2 {
        height: 512px;
    }
}

@media (min-height: 950px) {
    .heightCustom2 {
        height: 520px;
    }
}

@media (min-height: 959px) {
    .heightCustom2 {
        height: 530px;
    }
}

@media (min-height: 959px) {
    .heightCustom2 {
        height: 530px;
    }
}

@media (min-height: 970px) {
    .heightCustom2 {
        height: 540px;
    }
}

@media (min-height: 980px) {
    .heightCustom2 {
        height: 550px;
    }
}

@media (min-height: 990px) {
    .heightCustom2 {
        height: 560px;
    }
}

@media (min-height: 1000px) {
    .heightCustom2 {
        height: 570px;
    }
}

@media (min-height: 1010px) {
    .heightCustom2 {
        height: 580px;
    }
}

@media (min-height: 1360px) {
    .heightCustom2 {
        height: 1070px;
    }
}