/***component Register Data***/

.alignSubtitleContent {
    text-align: center !important;
}

.widthSelectCustom {
    width: 100%;
}

.widthCustom {
    width: 90%;
}

.marginCustom {
    margin-left: 10px;
    margin-right: 10px;
}

.selectNoBorder {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
}

#txtFaltos-label,
#txtAsignados-label,
#txtPresentes-label,
#txtTardanzas-label,
#txtCalPrese-label {
    font-size: 1em;
    /*font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;*/
    line-height: 1.6;
    letter-spacing: 0.0075em;
    /*color: #212529;*/
}

#txtFaltos,
#txtAsignados,
#txtPresentes,
#txtTardanzas,
#txtCalPrese {
    text-align: center;
    margin-top: 10px;
}


/*
.MuiInput-underline:before {
    border-bottom: 1px solid black !important;
}*/

.paddingItems {
    padding-right: 5px;
    padding-left: 5px;
    margin: 2px;
}

.colorBackground {
    color: white;
    background-color: #009DE4;
}

.showBorder {
    border: 1px solid red;
    border-radius: 5px;
}

.hideBorder {
    border: none;
}

input:disabled {
    color: black !important;
    cursor: default;
    background-color: -internal-light-dark(rgb(235, 235, 228), rgb(45, 45, 45));
}


/**-------------------------**/


/**-------------------------**/

.titulo {
    color: #c9cfd7;
    font-size: 14px !important;
    font-family: Helvetica !important;
}

.overflow-ellipsis {
    text-overflow: ellipsis;
}