.widthCustom{
    width: 100%;
}
.widthCustom2{
    width: 80%;
}
#txtDateRegister, #txtAreaDetail, 
#txtCompanyDetail, #txtTurnDetail{
    text-align: left;
}

#txtAsignDetail,#txtPresDetail,
#txtfaltDetail,#txtTardDetail,#txtCalPresenDetail{
    text-align: center;
}

.overflow-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
}

#detailp{
    width: 100%;
    border: 1px solid gainsboro;
    padding: 2px 5px;
    /*white-space: nowrap;*/
    overflow: hidden;
    text-align: justify;
}

.block-ellipsis {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    height: 38px;
    margin: 0 auto;
    font-size: 14px;
    line-height: 1;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 19px;
  }
  .fontSizeCustom{
      font-size: 14px;
  }

  .hideDescriptionMore{
      display: none;
  }
  .showDescriptionMore{
    display: block;
}