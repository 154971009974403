.ex2 {
    width: 100%;
    overflow: scroll;
}

.heightRecord2 {
    height: 520px;
}

@media (min-height: 475px) {
    .heightRecord2 {
        height: 400px;
    }
}

@media (min-height: 485px) {
    .heightRecord2 {
        height: 420px;
    }
}

@media (min-height: 495px) {
    .heightRecord2 {
        height: 430px;
    }
}

@media (min-height: 507px) {
    .heightRecord2 {
        height: 440px;
    }
}

@media (min-height: 517px) {
    .heightRecord2 {
        height: 450px;
    }
}

@media (min-height: 528px) {
    .heightRecord2 {
        height: 460px;
    }
}

@media (min-height: 536px) {
    .heightRecord2 {
        height: 470px;
    }
}

@media (min-height: 546px) {
    .heightRecord2 {
        height: 480px;
    }
}

@media (min-height: 556px) {
    .heightRecord2 {
        height: 490px;
    }
}

@media (min-height: 568px) {
    .heightRecord {
        height: 500px;
    }
}

@media (min-height: 578px) {
    .heightRecord2 {
        height: 510px;
    }
}

@media (min-height: 588px) {
    .heightRecord2 {
        height: 520px;
    }
}

@media (min-height: 599px) {
    .heightRecord2 {
        height: 530px;
    }
}

@media (min-height: 609px) {
    .heightRecord2 {
        height: 540px;
    }
}

@media (min-height: 619px) {
    .heightRecord2 {
        height: 550px;
    }
}

@media (min-height: 630px) {
    .heightRecord2 {
        height: 560px;
    }
}

@media (min-height: 640px) {
    .heightRecord2 {
        height: 570px;
    }
}

@media (min-height: 652px) {
    .heightRecord2 {
        height: 580px;
    }
}

@media (min-height: 660px) {
    .heightRecord2 {
        height: 590px;
    }
}


/***/

@media (min-height: 667px) {
    .heightRecord2 {
        height: 595px;
    }
}

@media (min-height: 670px) {
    .heightRecord2 {
        height: 595px;
    }
}

@media (min-height: 680px) {
    .heightRecord2 {
        height: 590px;
    }
}

@media (min-height: 690px) {
    .heightRecord2 {
        height: 600px;
    }
}

@media (min-height: 700px) {
    .heightRecord2 {
        height: 610px;
    }
}

@media (min-height: 710px) {
    .heightRecord2 {
        height: 620px;
    }
}

@media (min-height: 710px) {
    .heightRecord2 {
        height: 630px;
    }
}

@media (min-height: 720px) {
    .heightRecord2 {
        height: 640px;
    }
}

@media (min-height: 730px) {
    .heightRecord2 {
        height: 650px;
    }
}

@media (min-height: 740px) {
    .heightRecord2 {
        height: 660px;
    }
}

@media (min-height: 750px) {
    .heightRecord2 {
        height: 670px;
    }
}

@media (min-height: 760px) {
    .heightRecord2 {
        height: 680px;
    }
}


/***/

@media (min-height: 770px) {
    .heightRecord2 {
        height: 680px;
    }
}

@media (min-height: 780px) {
    .heightRecord2 {
        height: 690px;
    }
}

@media (min-height: 790px) {
    .heightRecord2 {
        height: 690px;
    }
}

@media (min-height: 800px) {
    .heightRecord2 {
        height: 700px;
    }
}

@media (min-height: 810px) {
    .heightRecord2 {
        height: 710px;
    }
}

@media (min-height: 820px) {
    .heightRecord2 {
        height: 750px;
    }
}

@media (min-height: 830px) {
    .heightRecord2 {
        height: 760px;
    }
}

@media (min-height: 840px) {
    .heightRecord2 {
        height: 770px;
    }
}

@media (min-height: 850px) {
    .heightRecord2 {
        height: 780px;
    }
}

@media (min-height: 860px) {
    .heightRecord2 {
        height: 790px;
    }
}

@media (min-height: 870px) {
    .heightRecord2 {
        height: 800px;
    }
}

@media (min-height: 880px) {
    .heightRecord2 {
        height: 810px;
    }
}

@media (min-height: 890px) {
    .heightRecord2 {
        height: 820px;
    }
}

@media (min-height: 900px) {
    .heightRecord2 {
        height: 830px;
    }
}

@media (min-height: 910px) {
    .heightRecord2 {
        height: 840px;
    }
}

@media (min-height: 920px) {
    .heightRecord2 {
        height: 850px;
    }
}

@media (min-height: 930px) {
    .heightRecord2 {
        height: 860px;
    }
}

@media (min-height: 940px) {
    .heightRecord2 {
        height: 870px;
    }
}

@media (min-height: 950px) {
    .heightRecord2 {
        height: 880px;
    }
}

@media (min-height: 960px) {
    .heightRecord2 {
        height: 880px;
    }
}

@media (min-height: 970px) {
    .heightRecord2 {
        height: 890px;
    }
}

@media (min-height: 980px) {
    .heightRecord2 {
        height: 900px;
    }
}

@media (min-height: 990px) {
    .heightRecord2 {
        height: 910px;
    }
}

@media (min-height: 1000px) {
    .heightRecord2 {
        height: 920px;
    }
}

@media (min-height: 1010px) {
    .heightRecord2 {
        height: 930px;
    }
}

@media (min-height: 1360px) {
    .heightRecord2 {
        height: 1268px;
    }
}