.hideButton {
    display: none;
}

.showButton {
    display: block;
}

#inputIdAmountDialog {
    text-align: center !important;
}