.contentProgress{
    background-color: transparent !important;
    box-shadow: unset !important;
}

#idDialogProgress > .MuiDialog-container > .MuiDialog-paper{
  margin: 32px;
  position: relative;
  overflow-y: auto;
  background-color: transparent !important ;
  box-shadow: unset !important;
}