.colorBackground {
    color: white !important;
    background-color: #47525E !important;
}

.colorBackground2 {
    color: white !important;
    background-color: #009DE4 !important;
}

.colorBtnConfirmBackground {
    color: white !important;
    background-color: #11AE8F !important;
}

.colorBtnCloseBackground {
    color: white !important;
    background-color: #8F9394 !important;
}

.width40 {
    width: 40% !important;
}

.width100 {
    width: 100% !important;
}

.showBorder {
    border: 1px solid red !important;
    border-radius: 5px !important;
}

.hideBorder {
    border: none;
}

#btnNewRegister {
    position: absolute;
    margin-left: -85px;
    margin-top: 0px;
}

#btnNewPersonBudgeted {
    position: absolute;
    margin-left: -85px;
    margin-top: 0px;
    background-color: #47525E !important;
}

#addRegisterBudgeted {
    background-color: #009DE4 !important;
}

#btnNewPersonBudgeted:hover {
    background-color: #595e64 !important;
}

.letterCapitalize {
    text-transform: capitalize;
}

#btnNewRegister {
    text-transform: capitalize;
}

.btnCloseNewPersonBudgeted {
    background-color: #47525E !important;
}

#btnSaveRegisterPersonBudgeted {
    background-color: #009DE4 !important;
}

#btnSaveAmount {
    background-color: #595e64 !important;
}