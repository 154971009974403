.makeStyles-root-124 {
    background-color: transparent !important;
}

.MuiTabs-fixed {
    width: 100%;
    background-color: transparent !important;
}

.makeStyles-root-118 {
    max-width: 100%;
}

.MuiTab-textColorPrimary.Mui-selected {
    color: #009DE4 !important;
}

#scrollable-force-tab-0, #scrollable-force-tab-1{
    border: 1px solid #009DE4;
}
/*
#tabTray{
    width:100%;
    height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
}*/
.ex1 {    
    width: 100%;    
    overflow: scroll;
}
.heightRecord{
    height: 520px;
}

@media (min-height: 475px) {
    .heightRecord{
        height: 320px ;
    }
}
@media (min-height: 485px) {
    .heightRecord{
        height: 330px ;
    }
}
@media (min-height: 495px) {
    .heightRecord{
        height: 340px ;
    }
}
@media (min-height: 507px) {
    .heightRecord{
        height: 350px ;
    }
}

@media (min-height: 517px) {
    .heightRecord{
        height: 360px ;
    }
}
@media (min-height: 528px) {
    .heightRecord{
        height: 370px ;
    }
}
@media (min-height: 536px) {
    .heightRecord{
        height: 380px ;
    }
}
@media (min-height: 546px) {
    .heightRecord{
        height: 390px ;
    }
}
@media (min-height: 556px) {
    .heightRecord{
        height: 400px ;
    }
}

@media (min-height: 568px) {
    .heightRecord{
        height: 410px ;
    }
}
@media (min-height: 578px) {
    .heightRecord{
        height: 420px ;
    }
}
@media (min-height: 588px) {
    .heightRecord{
        height: 430px ;
    }
}
@media (min-height: 599px) {
    .heightRecord{
        height: 440px ;
    }
}
@media (min-height: 609px) {
    .heightRecord{
        height: 450px ;
    }
}

@media (min-height: 619px) {
    .heightRecord{
        height: 460px ;
    }
}

@media (min-height: 630px) {
    .heightRecord{
        height: 470px ;
    }
}
@media (min-height: 640px) {
    .heightRecord{
        height: 480px ;
    }
}
@media (min-height: 652px) {
    .heightRecord{
        height: 490px ;
    }
}
@media (min-height: 660px) {
    .heightRecord{
        height: 500px ;
    }
}
@media (min-height: 667px) {
    .heightRecord{
        height: 510px ;
    }
}
@media (min-height: 670px) {
    .heightRecord{
        height: 520px ;
    }
}
@media (min-height: 680px) {
    .heightRecord{
        height: 530px ;
    }
}
@media (min-height: 690px) {
    .heightRecord{
        height: 540px ;
    }
}
@media (min-height: 700px) {
    .heightRecord{
        height: 550px ;
    }
}
@media (min-height: 710px) {
    .heightRecord{
        height: 560px ;
    }
}
@media (min-height: 710px) {
    .heightRecord{
        height: 570px ;
    }
}
@media (min-height: 720px) {
    .heightRecord{
        height: 580px ;
    }
}
@media (min-height: 730px) {
    .heightRecord{
        height: 590px ;
    }
}
@media (min-height: 740px) {
    .heightRecord{
        height: 600px ;
    }
}
@media (min-height: 750px) {
    .heightRecord{
        height: 610px ;
    }
}
@media (min-height: 760px) {
    .heightRecord{
        height: 620px ;
    }
}
@media (min-height: 770px) {
    .heightRecord{
        height: 630px ;
    }
}
@media (min-height: 780px) {
    .heightRecord{
        height: 640px ;
    }
}
@media (min-height: 790px) {
    .heightRecord{
        height: 650px ;
    }
}
@media (min-height: 800px) {
    .heightRecord{
        height: 660px ;
    }
}
@media (min-height: 810px) {
    .heightRecord{
        height: 670px ;
    }
}
@media (min-height: 820px) {
    .heightRecord{
        height: 710px ;
    }
}
@media (min-height: 830px) {
    .heightRecord{
        height: 720px ;
    }
}
@media (min-height: 840px) {
    .heightRecord{
        height: 730px ;
    }
}
@media (min-height: 850px) {
    .heightRecord{
        height: 740px ;
    }
}
@media (min-height: 860px) {
    .heightRecord{
        height: 750px ;
    }
}
@media (min-height: 870px) {
    .heightRecord{
        height: 760px ;
    }
}
@media (min-height: 880px) {
    .heightRecord{
        height: 770px ;
    }
}
@media (min-height: 890px) {
    .heightRecord{
        height: 780px ;
    }
}
@media (min-height: 900px) {
    .heightRecord{
        height: 790px ;
    }
}
@media (min-height: 910px) {
    .heightRecord{
        height: 800px ;
    }
}
@media (min-height: 920px) {
    .heightRecord{
        height: 810px ;
    }
}
@media (min-height: 930px) {
    .heightRecord{
        height: 820px ;
    }
}
@media (min-height: 940px) {
    .heightRecord{
        height: 830px ;
    }
}
@media (min-height: 950px) {
    .heightRecord{
        height: 840px ;
    }
}
@media (min-height: 959px) {
    .heightRecord{
        height: 850px ;
    }
}
@media (min-height: 959px) {
    .heightRecord{
        height: 860px ;
    }
}
@media (min-height: 970px) {
    .heightRecord{
        height: 870px ;
    }
}
@media (min-height: 980px) {
    .heightRecord{
        height: 880px ;
    }
}
@media (min-height: 990px) {
    .heightRecord{
        height: 890px ;
    }
}
@media (min-height: 1000px) {
    .heightRecord{
        height: 900px ;
    }
}
@media (min-height: 1010px) {
    .heightRecord{
        height: 910px ;
    }
}
@media (min-height: 1360px) {
    .heightRecord{
        height: 1070px ;
    }
}