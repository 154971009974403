.showGrid{
    display: block;
}
.hideGrid{
    display: none;
}

.selectCamera{
    position: fixed;
    top: 60px;
    z-index: 10;
    width: 100%
}

.buttonFloat{
    position: fixed;
    width: 100px;
    left: calc(50% - 100px)
}

html, body{
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
}

video, .cameraVideo, .cameraImageCapture{
    position: fixed;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.containerButton{
    position: fixed;
    bottom: 10px;
    width: 200px;
    left: calc(50% - 50px);
}

.containerButton2{
    position: fixed;
    bottom: 10px;
    width: 200px;
    left: calc(50% - 100px);
}

.App{
    text-align: left !important;
}